import "./App.css";
import Slider from "./Components/slider/slider";
// import Footer from "./Components/footer/footer";
import Contact from "./Components/contact/contact";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Tender from "./Components/tender/tender";

function App() {
  return (
    <Router>
      <Route exact path="/">
        <Slider />
        {/* <Footer /> */}
      </Route>

      <Route path="/tender">
        <Tender />
      </Route>

      <Route path="/contact">
        <Contact />
      </Route>
    </Router>
  );
}

export default App;
