import React from "react";
import style from "./footer.module.css";
// import { FaMobileAlt, FaLocationArrow } from "react-icons/fa";

export default function footer() {
  return (
    <footer className={style.container}>
      <img
        className={style.footerlogo}
        src={"public/assets/footerLogo.png"}
        alt="footer"
      />
      {/* <div className={style.contact}>
              <h2>American Hospital</h2>
            </div> */}{" "}
      <div className={style.contact_info}>
        <div className={style.contac_detailed}>
          {" "}
          {/* <FaMobileAlt />{" "} */}
          <span className={style.streetName}> 00994 502459303 </span>{" "}
        </div>{" "}
        <div className={style.location}>
          {/* <FaLocationArrow />{" "} */}
          <div className={style.streetName}>
            {" "}
            Yusif Səfərov 31,AZ 1025 , Bakı Azərbaycan{" "}
          </div>{" "}
          <div className={style.streetName}> info@americanhospital.az</div>{" "}
        </div>{" "}
      </div>{" "}
    </footer>
  );
}
