import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, EffectFade } from "swiper";

import "swiper/css";

import "swiper/css/effect-fade";
import "swiper/css/navigation";
import styles from "./slider.module.css";

// import Link from "next/link";
import { FaLinkedin, FaFacebook, FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";

// import Flip from "react-reveal/Flip";

// import logo  from "../../../public/assets/navbarLogo.png"

export default function Slider() {
  return (
    <div className={styles.container}>
      <img
        className={styles.navbarlogo}
        src={"assets/navbarlogo.png"}
        alt="navbar"
      />
      <div className={styles.social_icons}>
        <FaFacebook size={25} /> <FaLinkedin size={25} />{" "}
        <FaWhatsapp size={25} />{" "}
      </div>
      <div className={styles.companyName}>
        {" "}
        Tibbi Xidmət dəyişir...
        <br /> Yeni Era American Standartları ilə... <br />
      </div>
      {/* 
            <Link href="/contact"> */}
      <div className={styles.buttons}>
        <Link to="/contact">
          <div className={styles.contact}> Əlaqə </div>{" "}
        </Link>{" "}
        {/* </Link> */}
        {/* <Link href="/tenders"> */}{" "}
        <Link to="/tender">
          <div className={styles.tender}> Tenderlər </div>{" "}
        </Link>
      </div>
      {/* </Link> */}
      <Swiper
        modules={[Navigation, EffectFade]}
        navigation
        effect
        speed={800}
        spaceBetween={50}
        slidesPerView={1}
        loop={true}
        className={styles.myswiper}
        //   onSlideChange={() => console.log('slide change')}
        //   onSwiper={(swiper) => console.log(swiper)}
      >
        <SwiperSlide className={styles.swiperslider}>
          <img
            className={styles.image}
            src={
              "https://ahtbilisi.com/wp-content/uploads/2022/07/მთავარი-გვერდი-ქავერუ.jpg"
            }
            alt="slider"
          />
        </SwiperSlide>{" "}
        <SwiperSlide>
          {" "}
          <div>
            <img
              alt="slider"
              className={styles.image}
              src={"https://ahtbilisi.com/wp-content/uploads/2022/03/Eng.png"}
            />{" "}
          </div>{" "}
        </SwiperSlide>{" "}
        <SwiperSlide>
          <img
            alt="slider"
            className={styles.image}
            src={
              "https://ahtbilisi.com/wp-content/uploads/2022/01/დეპ.-ქავერი.png"
            }
          />{" "}
        </SwiperSlide>
        {/* <SwiperSlide className={styles.swiperslider}>
                <img
                  alt="slider"
                  className={styles.image}
                  src={"https://ahtbilisi.com/wp-content/uploads/2022/03/Geo.png"}
                  />
              </SwiperSlide> */}
        {/* <SwiperSlide className={styles.swiperslider}>
                <img
                  alt="slider"
                  className={styles.image}
                  src={
                    "https://ahtbilisi.com/wp-content/uploads/2022/07/მთავარი-გვერდი-ქავერუ.jpg"
                  }
          
                />
              </SwiperSlide> */}{" "}
      </Swiper>{" "}
    </div>
  );
}
