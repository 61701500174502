import style from "./contact.module.css";
import { MdOutlineEmail } from "react-icons/md";
import { Link } from "react-router-dom";
import Footer from "../footer/footer";
import { FaMobileAlt } from "react-icons/fa";

function Form() {
  return (
    <>
      <div className={style.tenderConTainer}>
        <Link to="/">
          <img
            className={style.navbarlogo}
            src={"assets/navbarlogo.png"}
            alt="navbar"
          />
        </Link>
        <div className={style.NameSername}>
          Ad və Soyadınızı qeyd edərək müraciətinizi bizə email <br />{" "}
          vasitəsilə göndərin
        </div>

        <article className={style.contact__option}>
          <a
            className={style.message}
            href="mailto:info@americanhospital.az"
            target="_blank"
            rel="noreferrer"
          >
            <MdOutlineEmail className={style.contact__option_icon} />{" "}
            <h2> Email </h2> <h2> info@americanhospital.az </h2>
            {/* <a href="mailto:someone@example.com">Send email</a> */}{" "}
            <h2> Mesajınızı klik edərək göndərə bilərsiz </h2>
          </a>{" "}
        </article>

        <article className={`${style.contact__option} ${style.contact_mobile}`}>
          <FaMobileAlt className={style.contact__option_icon} />{" "}
          <h2> Telefon</h2> <h2> 00994 502459303</h2>
          {/* <a href="mailto:someone@example.com">Send email</a> */}{" "}
        </article>
      </div>

      <div className={style.footer}>
      <Footer />
      </div>

    </>
  );
}
export default Form;
