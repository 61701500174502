import React from "react";
import style from "./tender.module.css";
import Footer from "../footer/footer";
import { Link } from "react-router-dom";

export default function tender() {
  return (
    <>
      <div className={style.container}>
        {/* <h1 className={style.mainHeader}>
          {" "}
          Amerikan Bakı olaraq bizə aşağıdakı təkliflərdən birini verə bilərsiz{" "}
        </h1> */}
        <Link to="/">
          <img
            className={style.navbarlogo}
            src={"assets/navbarlogo.png"}
            alt="navbar"
          />
        </Link>
        <p className={`${style.title} ${style.phoneTitle}`}>
          {" "}
          American Hospital MMC tikinti prosessinin təşkili zamanı <br />
          aşağıdakı mövzular üzrə katriovka sorğuları edir:{" "}
        </p>
        <p className={style.title}> Elektrik </p>
        <ul>
          <li> Panolar </li> <li> Yanmaz Kabellər və Kabel Kanalları </li>{" "}
          <li> Generatorlar </li> <li> Transfarmatorlar </li>{" "}
          <li> UPS– lər </li> <li> Prizlər </li> <li> Busbar </li>{" "}
          <li> İzolyasiyalı güc </li> <li> Zəif Axım və Qüvvətli axım </li>{" "}
          <li> Və s </li>{" "}
        </ul>{" "}
        <p className={style.title}> Mexanik </p>
        <ul>
          <li> Panolar </li> <li> Təmiz su borusu və boru aksessuarları </li>{" "}
          <li> Çirkli su borusu və boru aksessuarları </li>{" "}
          <li> Hidroforlar </li> <li> UPS– lər </li>{" "}
          <li> Yanğınla mübarizə sistemi </li> <li> Pompalar </li>{" "}
          <li> Hamam aksessuarları </li> <li> Su depoları </li>{" "}
          <li> Havalandırma sistemləri </li> <li> Havalandırma sistemləri </li>{" "}
          <li> İsitmə və soyutma sistemləri </li> <li> Və s </li>
        </ul>
        <p className={style.apply}>
          {" "}
          Yuxarıdakı mövzular üzrə və ya təmir prosessində istifadə edə
          biləcəyimiz hər hansı mövzu üzrə <br /> təklif vermək istəyirsinizsə
          firma portfolionuzu və təklif vermək <br /> istədiyiniz mövzunu qeyd
          edərək firmanız haqqında bizə məlumat verin.Uyğun olduğu təqdirdə{" "}
          <br /> biz sizə geri dönub daha detallı məlumat üçün görüşə dəvət
          edəcəyik.{" "}
        </p>
        {/* <Link to="/">
          <button className={style.contact}> Əsas səhifəyə qayıdın </button>{" "}
        </Link> */}
      </div>{" "}
      {/* <div className={style.info}> */}
      {/* <div className={style.info_locotion}>
          {" "}
          <FaLocationArrow />{" "}
          <span className={style.locotion}>
            {" "}
            Ünvanımız Yusif Səfərov 31{" "}
          </span>{" "}
        </div>
        <div className={style.info_locotion}>
          <FaMobileAlt />{" "}
          <span className={style.number}> Nömrə 050 245 93 03 </span>{" "}
        </div> */}
      {/* </div> */}
      <div className={style.main_contact_option}>
        <article className={style.contact__option}>
          <a
            className={style.message}
            href="mailto: info@americanhospital.az"
            target="_blank"
            rel="noreferrer"
          >
            {/* <MdOutlineEmail className={style.contact__option_icon} />{" "} */}
            <h2> info@americanhospital.az </h2>
            {/* <a href="mailto:someone@example.com">Send email</a> */}{" "}
            <h2> Emailinizi klik edərək göndərə bilərsiz </h2>
          </a>{" "}
        </article>
      </div>
      <Footer />
    </>
  );
}
